.single-inspection-container {
    --morning-blue: rgb(183, 194, 193);
    --gunmetal: rgb(31, 36, 43);
    border: 2px solid rgb(25, 29, 34);
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px 1px rgb(25, 29, 34);
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}

.single-inspection-header-container {
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: var(--morning-blue);
    font-size: xx-large;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}

.info-label-container {
    /* border: 3px solid green; */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.label-container {
    /* border: 3px solid blue; */
    color: var(--morning-blue);
    font-size: larger;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.info-container {
    font-size: larger;
    color: rgb(255, 255, 255);
    font-weight: 500;
}

.info-container-passing {
    font-size: larger;
    color: rgb(255, 255, 255);
    font-weight: 500;
    color: rgb(6, 182, 6);
}

.info-container-failed {
    font-size: larger;
    color: rgb(255, 255, 255);
    font-weight: 500;
    color: rgb(255, 37, 37)
}

.inspection-answer-container {
    /* border: 2px solid red; */
    margin-bottom: 20px;
    border: 2px solid var(--light-gunmetal);
    padding: 10px;
    border-radius: 5px;
}

.inspection-answer-failed-container {
    /* border: 2px solid red; */
    margin-bottom: 20px;
    border: 2px solid var(--light-gunmetal);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

.inspection-answer-left-container {
    /* border: 2px solid green; */
    width: 69%;
}

.inspection-answer-right-container {
    /* border: 2px solid yellow; */
    width: 25%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inspection-photo-container {
    /* border: 2px solid purple; */
    height: 150px;
    width: 140px;
    border-radius: 10px;
    overflow: hidden;
}

.question-container {
    /* border: 3px solid yellow; */
    font-size: large;
    display: flex;
    margin-bottom: 10px;
}

.question-label {
    font-size: large;
    margin-right: 5px;
}

.question {
    color: rgb(255, 255, 255);
}

.question-passing-container {
    display: flex;
    align-items: center;
    margin-left: 80px;
    margin-bottom: 5px;
}

.question-passing-label {
    margin-right: 10px;
}

.question-passing-passed {
    color: rgb(6, 182, 6);
}

.question-passing-failed {
    color: rgb(255, 37, 37);
}

.comment-container {
    display: flex;
    margin-left: 80px;
}

.comment-label {
    margin-right: 10px;
}

.comment {
    color: rgb(255, 255, 255);
}

.edit-inspection-button-container {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.edit-inspection-button-container>button {
    width: 40%;
    height: 50px;
    background-color: var(--light-gunmetal);
    color: lightgray;
    border-radius: 10px;
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    box-sizing: border-box;
}

.edit-inspection-button-container>button:hover {
    cursor: pointer;
    background-color: var(--gunmetal);
}

.edit-inspection-button {
    width: 40%;
    height: 50px;
    background-color: var(--light-gunmetal);
    color: lightgray;
    border-radius: 10px;
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    box-sizing: border-box;
}

.edit-inspection-button:hover {
    cursor: pointer;
    background-color: var(--gunmetal);
}
