.inspection-list-container {
    border: 2px solid rgb(25, 29, 34);
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px 1px rgb(25, 29, 34);
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    height: fit-content;
}

.inspection-list-header-container {
    display: flex;
    justify-content: center;
}

.inspection-list-header {
    /* border: 3px solid  yellow; */
    margin-bottom: 20px;
}

.inspection-container-header {
    border: 4px solid var(--light-gunmetal);
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;
    align-items: center;
    font-size: large;
    padding-top: 10px;
    padding-bottom: 10px;
    --light-gunmetal: rgb(49, 58, 70)
}

.inspection-container-nav {
    border: 2px solid var(--light-gunmetal);
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}

.inspection-container-nav:hover {
    border: 2px solid rgb(85, 92, 102)
}

.id-container {
    box-sizing: border-box;
    width: 10%;
    display: flex;
    justify-content: center;
    border-right: 2px solid var(--light-gunmetal);
}

.address {
    width: 45%;
    display:flex;
    justify-content: center;
    text-align: center;
}

.inspection-date {
    width: 18%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-left: 2px solid var(--light-gunmetal);
    border-right: 2px solid var(--light-gunmetal);
    height: 100%;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.inspection-number {
    width: 10%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--light-gunmetal);
    height: 100%;
    text-align: center;
    padding-right: 10px
}

.status {
    width: 10%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
}

.status-passed {
    width: 10%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    color: rgb(6, 182, 6);
}

.status-failed {
    width: 10%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    color: rgb(255, 37, 37)
}
