#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

#modal-content-container {
    position: absolute;
    background-color: rgb(66, 76, 90);
    border-radius: 10px;
    max-width: 40vw;
    max-height: 80vh;
    padding: 10px;
    /* overflow-y: hidden; */
    /* display: flex; */
    justify-content: center;
}

#modal-content {
    background-color: rgb(66, 76, 90);
    border-radius: 10px;
    max-width: 100%;
    max-height: 75vh;
    padding: 10px;
    overflow-y: auto;
    scrollbar-track-color: var(--gunmetal);
    scrollbar-base-color: gray;
}

#photo-modal-content-container {
    position: absolute;
    background-color: rgb(66, 76, 90);
    border-radius: 10px;
    width: 95%;
    height: 95%;
    padding: 10px;
    justify-content: center;
    box-sizing: border-box;

}

#photo-modal-content {
    background-color: rgb(66, 76, 90);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 10px;
    scrollbar-track-color: var(--gunmetal);
    scrollbar-base-color: gray;
    box-sizing: border-box;
    overflow: hidden;
}
