
.single-address-container {
    --morning-blue: rgb(183, 194, 193);
    --gunmetal: rgb(31, 36, 43);
    border: 2px solid rgb(25, 29, 34);
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px 1px rgb(25, 29, 34);
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
}

.single-address-header-container {
    /* border: 3px solid yellow; */
    display: flex;
    justify-content: center;
}

.info-label-container {
    /* border: 3px solid green; */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.label-container {
    /* border: 3px solid blue; */
    color: var(--morning-blue);
    font-size: larger;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.info-container {
    font-size: larger;
    color: rgb(255, 255, 255);
    font-weight: 500;
}

.address-map-container-outer {
    border: 2px solid var(--light-gunmetal);
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
}

.address-map-container {
    width: 100%;
    border-radius: 10px;
    height: 50vh
}

.address-inspections-container {
    border: 2px solid var(--light-gunmetal);
    padding: 30px;
    padding-top: 0px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.address-inspections-header {
    border-bottom: 4px solid var(--light-gunmetal);
    padding-bottom: 10px;
    font-size: x-large;
    text-align: center;
}

.address-inspection-container-header {
    border: 4px solid var(--light-gunmetal);
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;
    align-items: center;
    font-size: large;
    padding-top: 10px;
    padding-bottom: 10px;
    --light-gunmetal: rgb(49, 58, 70)
}

.address-inspection-container-nav {
    border: 2px solid var(--light-gunmetal);
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}

.address-inspection-container-nav:hover {
    border: 2px solid rgb(85, 92, 102)
}

.address-inspection-container-nav:hover>.address-inspection-answers-container>.inspection-answer-container {
    border: 2px solid rgb(85, 92, 102)
}

.address-id-container {
    box-sizing: border-box;
    width: 15%;
    display: flex;
    justify-content: center;
    border-right: 2px solid var(--light-gunmetal);
}

.address-inspection-date {
    width: 25%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--light-gunmetal);
    height: 100%;
    text-align: center;
    padding-right: 10px;
}

.address-inspection-number {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--light-gunmetal);
    height: 100%;
    text-align: center;
}

.address-inspection-inspector {
    width: 30%;
    border-right: 2px solid var(--light-gunmetal);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
}

.address-inspection-status {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
}

.address-inspection-status-passed {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    color: rgb(6, 182, 6);
}

.address-inspection-status-failed {
    width: 15%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    color: rgb(255, 37, 37)
}

.image-preview-container {
    height: 200px;
    width: 200px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    overflow: hidden;
}

.image-preview {
    width: 100%;
    height: 100%;
}

.address-responses-container{
    border: 2px solid var(--light-gunmetal);
    padding: 30px;
    padding-top: 0px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.address-responses-header {
    border-bottom: 4px solid var(--light-gunmetal);
    padding-bottom: 10px;
    font-size: x-large;
    text-align: center;
}

.address-response-container-header {
    border: 4px solid var(--light-gunmetal);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    align-items: center;
    font-size: large;
    padding-top: 10px;
    /* padding-bottom: 10px; */
    --light-gunmetal: rgb(49, 58, 70)
}

.address-response-container {
    border: 2px solid var(--light-gunmetal);
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}

.address-response-top-container {
    /* border: 2px solid green; */
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 5px;
    border-bottom: 2px solid var(--light-gunmetal);
}

.address-response-id-container {
    box-sizing: border-box;
    width: 15%;
    display: flex;
    justify-content: center;
    border-right: 2px solid var(--light-gunmetal);
}


.address-response-date {
    width: 30%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--light-gunmetal);
    height: 100%;
    text-align: center;
    padding-right: 10px;
}

.address-response-dept {
    width: 25%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-right: 2px solid var(--light-gunmetal);
    height: 100%;
    text-align: center;
    padding-right: 10px;
}

.address-response-type {
    width: 20%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
}


.address-response-bottom-container {
    /* border: 2px solid red; */
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    height: 125px;
}

.address-response-units {
    /* border: 2px solid yellow; */
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    /* text-decoration: underline; */
}

.address-response-units-header {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    margin-top: 5px;
}

.address-response-units-list {
    /* border: 2px solid brown; */
    box-sizing: border-box;
    width: 90%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.address-response-description {
    /* border: 2px solid purple; */
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.address-response-description-header {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    margin-top: 5px;
}

.address-response-description-text {
    box-sizing: border-box;
    width: 90%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
